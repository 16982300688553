import React, { useState, useEffect } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Selection, Inject, Edit, Toolbar, Sort, Filter } from '@syncfusion/ej2-react-grids';
import { investorsData, investorsGrid } from '../data/dummy';
import { Header } from '../components';

const Investors = () => {
  const [data, setData] = useState();

  useEffect(() => {
    investorsData().then(PromiseResult => setData(PromiseResult));
  }, [])

  const toolbarOptions = ['Add', 'Edit', 'Delete', 'Save', 'Cancel'];
  const editing = { allowDeleting: true, allowEditing: true, allowAdding: true, showDeleteConfirmDialog: true, deleteConfirmDialogFields: ['InvestorName'] };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-8 bg-white rounded-3xl">
      <Header category="Page" title="Investors" />
      <GridComponent
        dataSource={data}
        enableHover={true}
        pageSettings={{ pageCount: 5 }}
        allowPaging
        width="auto"
        toolbar={toolbarOptions}
        editSettings={editing}
        deleteSettings={{
          showDeleteConfirmDialog: true,
          deleteConfirmDialogFields: ['InvestorName'],
          confirmDialogText: 'Are you sure you want to delete this record?',
          confirmDialogHeader: 'Delete Confirmation',
          actionBegin: function (args) {
            if (args.requestType === 'delete') {
              const deletedData = args.data;
              fetch(`/api/investors/${deletedData[0]._id}`, {
                method: 'DELETE',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer YOUR_TOKEN_HERE`,
                },
              })
                .then(response => response.json())
                .then(json => {
                  if (json.success) {
                    setData(data.filter(item => item._id !== deletedData[0]._id));
                  } else {
                    args.cancel = true;
                    alert('Error deleting record');
                  }
                })
                .catch(error => {
                  args.cancel = true;
                  alert('Error deleting record');
                  console.error(error);
                });
            }
          },
        }}
        allowSorting
      >
        <ColumnsDirective>
          {investorsGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
        </ColumnsDirective>
        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
      </GridComponent>
    </div>
  );
};

export default Investors;
