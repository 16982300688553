import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

import { Navbar, Footer, Sidebar, ThemeSettings } from "./components";
import {
  UserAnalytics,
  AdminAnalytics,
  Employees,
  Stacked,
  Investors,
  Line,
  Area,
  Login,
  Signup,
  CreateInvestors,
  CreateEmployees,
  UserConfig,
  RegisteredUsers,
} from "./pages";
import "./App.css";

import { useStateContext } from "./contexts/ContextProvider";
import { useAuthContext } from "./hooks/useAuthContext";

const App = () => {
  const { user } = useAuthContext();

  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
  } = useStateContext();

  useEffect(() => {
    const currentThemeColor = localStorage.getItem("colorMode");
    const currentThemeMode = localStorage.getItem("themeMode");
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  return (
    <div className={currentMode === "Dark" ? "dark" : ""}>
      <BrowserRouter>
        <div className="flex relative dark:bg-main-dark-bg">
          <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
            <TooltipComponent content="Settings" position="Top">
              <button
                type="button"
                onClick={() => setThemeSettings(true)}
                style={{ background: currentColor, borderRadius: "50%" }}
                className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
              >
                <FiSettings />
              </button>
            </TooltipComponent>
          </div>
          {activeMenu ? (
            <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
              <Sidebar />
            </div>
          ) : (
            <div className="w-0 dark:bg-secondary-dark-bg">
              <Sidebar />
            </div>
          )}
          <div
            className={
              activeMenu
                ? "dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  "
                : "bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 "
            }
          >
            <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
              <Navbar />
            </div>
            <div>
              {themeSettings && <ThemeSettings />}

              <Routes>
                {/* dashboard  */}
                <Route
                  path="/"
                  element={
                    user ? (
                      user.role === "admin" ? (
                        <AdminAnalytics />
                      ) : (
                        <UserAnalytics />
                      )
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                />
                <Route
                  path="/useranalytics"
                  element={
                    user ? (
                      user.role === "admin" ? (
                        <AdminAnalytics />
                      ) : (
                        <UserAnalytics />
                      )
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                />
                <Route
                  path="/adminanalytics"
                  element={
                    user ? (
                      user.role === "admin" ? (
                        <AdminAnalytics />
                      ) : (
                        <UserAnalytics />
                      )
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                />

                {/* pages */}
                <Route
                  path="/employees"
                  element={
                    user ? (
                      user.role === "admin" ? (
                        <Employees />
                      ) : (
                        <Navigate to="/useranalytics" />
                      )
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                />
                <Route
                  path="/investors"
                  element={
                    user ? (
                      user.role === "admin" ? (
                        <Investors />
                      ) : (
                        <Navigate to="/useranalytics" />
                      )
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                />
                <Route
                  path="/registeredusers"
                  element={
                    user ? (
                      user.role === "admin" ? (
                        <RegisteredUsers />
                      ) : (
                        <Navigate to="/useranalytics" />
                      )
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                />
                <Route
                  path="/createinvestors"
                  element={
                    user ? (
                      user.role === "admin" ? (
                        <CreateInvestors />
                      ) : (
                        <Navigate to="/useranalytics" />
                      )
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                />
                <Route
                  path="/createemployees"
                  element={
                    user ? (
                      user.role === "admin" ? (
                        <CreateEmployees />
                      ) : (
                        <Navigate to="/useranalytics" />
                      )
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                />
                <Route
                  path="/userconfig"
                  element={
                    user ? (
                      user.role === "admin" ? (
                        <UserConfig />
                      ) : (
                        <Navigate to="/useranalytics" />
                      )
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                />
                <Route
                  path="/login"
                  element={!user ? <Login /> : <Navigate to="/" />}
                />
                <Route
                  path="/signup"
                  element={!user ? <Signup /> : <Navigate to="/" />}
                />

                {/* charts  */}
                <Route path="/line" element={<Line />} />
                <Route path="/area" element={<Area />} />
                <Route path="/stacked" element={<Stacked />} />
              </Routes>
            </div>
            <Footer />
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
