import React from 'react';

const Footer = () => (
  <div className="mt-24">
    <h2 className="text-2xl bg-gradient-to-r from-blue-500 via-violet-400 to-sky-400 bg-clip-text text-transparent text-center m-20">
      This is a Sever Based Application, Select the options from the sidebar on the left for latest info
    </h2>
    <p className="dark:text-gray-200 text-gray-700 text-center m-20">
      © 2023 All rights reserved by vivegaminvestments.com
    </p>
  </div>
);

export default Footer;
