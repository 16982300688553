import React from 'react';

const Header = ({ category, title }) => (
  <div className=" mb-10">
    <p className="text-lg text-gray-400">{category}</p>
    <p className="text-3xl font-extrabold tracking-tight bg-gradient-to-r from-blue-600 via-violet-600 to-sky-200 bg-clip-text text-transparent">
      {title}
    </p>
  </div>
);

export default Header;
